import React from "react";
import { Github, Docker, Mailgun, Slack, Supabase } from "../components/Icons";
import Layout from "../components/Layout";
import Section from "../components/Section";
import TitleBar from "../components/TitleBar";
import Callout from "../components/Callout";
import Button from "../components/Button";

const Requirement = ({ title, subtitle, icon }) => {
  return (
    <li className="flex space-x-4 items-center">
      <span>{icon}</span>
      <span>
        <h4 className="text-[15px] opacity-80 m-0 font-semibold">{title}</h4>
        <p className="text-[14px] opacity-60 m-0 font-semibold">{subtitle}</p>
      </span>
    </li>
  );
};

const TableComponent = ({
  data
}) => {
  let headings = Object.keys(data[1]);
  return (
    <table className='w-full text-base'>
      <thead className="text-left">
        <tr>
          {
            headings.map(heading => <th className="p-2">{heading}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {
            data.map(item => 
              <tr className="odd:bg-gray odd:bg-opacity-40 rounded text-sm">
               {
                  headings.map(heading => <td className="p-2">{item[heading]}</td>) 
               }
              </tr>
            )
        }
      </tbody>
    </table>
  );
}

const QnaJsPricing = [{
  'Monthly pageviews': '0 to 100,000',
  'Price per pageview (USD)': ' ',
  'Min monthly price': '$0',
  'Max monthly price': '$0'
}, {
  'Monthly pageviews': '100,001 to 1,000,000',
  'Price per pageview (USD)': '$0.0001',
  'Min monthly price': '$0',
  'Max monthly price': '$100'
}, {
  'Monthly pageviews': '1,000,001 to 10,000,000',
  'Price per pageview (USD)': '$0.00006',
  'Min monthly price': '$100',
  'Max monthly price': '$640'
}, {
  'Monthly pageviews': '10,000,001',
  'Price per pageview (USD)': '$0.000036',
  'Min monthly price': '$640',
  'Max monthly price': '$3,880'
}];


const Pricing = () => {
  return (
    <Layout title="Pricing" description="Pricing for Squeak! Cloud and self-hosted">
      <TitleBar title="Pricing">
        <Button url="https://squeak.cloud/signup">
            Try Squeak! Cloud
        </Button>
      </TitleBar>
      <article>
        <Section id="pricing">
            <h2>Squeak! Cloud</h2>

            <Callout linkLabel="Browse roadmap" linkURL="/toolkit/qna.js#features" className="mt-4 mb-4">
              <div>
                <h4 className="text-xl mt-4"><strong>Squeak! Cloud is completely free to use</strong> until after beta.</h4>
                <p className="mt-0">Pricing below is for use after we complete the beta phase.</p>
              </div>
            </Callout>

            <TableComponent data={QnaJsPricing}/>


        </Section>

        <div className="border-t border-dashed border-gray pt-8">

          <Section id="self-host" className="w-full mx-auto">
              <h2>Self-hosting Squeak!</h2>
              <p>All tools will remain free with Squeak! Open Source.</p>

              <Button
                  url="https://github.com/posthog/squeak"
                  type="secondary"
                  icon={<Github />}
                >
                  Get started on GitHub
                </Button>

              <h3 className="pt-8">System requirements (self-host)</h3>
              <h4>
                You can sign up and install Squeak! in just a few clicks with{" "}
                <a href="https://squeak.cloud">Squeak! Cloud</a>, but if you'd
                prefer to host Squeak! yourself, here's what you'll need:
              </h4>
              <p className="my-4">You’ll need:</p>
              <ul className="space-y-3">
                <Requirement
                  title="Docker (and Docker hosting)"
                  subtitle="Docker (and Docker hosting)"
                  icon={<Docker />}
                />
                <Requirement
                  title="Supabase"
                  subtitle="Postgres hosting, authentication"
                  icon={<Supabase />}
                />
              </ul>
              <p className="my-4 mt-7">Available plugins:</p>
              <ul className="space-y-3">
                <Requirement
                  title="Slack"
                  subtitle="Moderator notifications for new questions and community replies"
                  icon={<Slack />}
                />
                <Requirement
                  title="Mailgun"
                  subtitle="Email notifications for thread replies"
                  icon={<Mailgun />}
                />
              </ul>
              <a
                href="https://github.com/PostHog/squeak"
                target="_blank"
                rel="noreferrer"
                className="font-semibold mt-5 inline-block"
              >
                Visit the README on GitHub →
              </a>
          </Section>
        </div>
      </article>
    </Layout>
  );
};

export default Pricing;
